/* Container Styling */
.certificate-details-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  background: linear-gradient(to bottom right, #ffffff, #f0f0f0);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
h2 {
  text-align: center;
  color: #4a4a4a;
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Error Message Styling */
.error-message {
  color: #ff4c4c;
  background-color: #ffe6e6;
  padding: 10px;
  border: 1px solid #ff4c4c;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

/* Certificate Card Styling */
.certificate-card {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.certificate-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.certificate-card p {
  margin: 8px 0;
  color: #333;
  font-size: 16px;
}

.certificate-card strong {
  color: #007bff;
}

/* Back Button Styling */
.back-btn {
  display: block;
  width: fit-content;
  margin: 20px auto 0;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Loading Message */
.loading {
  text-align: center;
  font-size: 18px;
  color: #888;
}

/* Responsive Design */
@media (max-width: 600px) {
  .certificate-details-container {
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }

  .certificate-card {
    padding: 10px;
  }

  .back-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}
