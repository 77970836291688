/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center; /* Ensures the popup is vertically centered */
    z-index: 1000;
  }
  
  /* Popup Content */
  .popup-content {
    background-color: white;
    padding: 10px;
    border-radius: 15px;
    width: 100%;
    max-width: 500px;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    animation: slideIn 0.3s ease-out; /* Optional: animation for popup appearance */
  }
  
  /* Popup Content Animation */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .popup-content h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .popup-content p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1px;
  }
  
  .popup-content .form-group {
    margin-bottom: 1px;
  }
  
  .popup-content .form-group label {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: -50px;
  }
  
  .popup-content .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .popup-content .form-group input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Submit Button */
  .register-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .register-button:disabled {
    background-color: #262323;
    cursor: not-allowed;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
  }
  
  .close-button:hover {
    color: #007bff;
  }
  
  /* Error and Success Messages */
  .error-message {
    background-color: #f44336;
    color: white;
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
  }
  
  .success-message {
    background-color: #4caf50;
    color: white;
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
  }
  
  .success-message h3 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .success-message p {
    font-size: 0.9rem;
    margin: 5px 0 0;
  }
  
  /* Responsiveness */
  @media (max-width: 600px) {
    .popup-content {
      padding: 20px;
      width: 90%;
    }
  
    .popup-content h2 {
      font-size: 1.2rem;
    }
  
    .popup-content .form-group input {
      font-size: 0.9rem;
    }
  
    .register-button {
      font-size: 0.9rem;
    }
  }
  
  .success-message {
    background-color: #4caf50;
    color: white;
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
  }
  
  .success-message h3 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .success-message p {
    font-size: 0.9rem;
    margin: 5px 0 0;
  }
  