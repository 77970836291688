/* Basic Styling */
.services-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .services-title {
    font-size: 3rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  .services-description {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #666;
  }
  
  .services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  .service-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 45%;
    transition: transform 0.3s ease-in-out;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
  }
  
  .service-title {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .service-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
  }
  
  .service-icon {
    font-size: 3rem;
    color: #00BFAE;
    margin-bottom: 20px;
  }
  
  .service-benefits {
    list-style-type: none;
    padding: 0;
    font-size: 1rem;
    color: #333;
  }
  
  .service-benefits li {
    margin-bottom: 10px;
  }
  
  /* Testimonials Section */
  .testimonials {
    margin-top: 50px;
  }
  
  .testimonials-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .testimonial-card {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .testimonial-text {
    font-style: italic;
    font-size: 1rem;
    color: #5c5353;
  }
  
  .testimonial-author {
    display: block;
    margin-top: 10px;
    font-weight: bold;
    color: #00BFAE;
  }
  
  /* Call to Action Section */
  .cta-container {
    background-color: #00BFAE;
    color: #fff;
    padding: 50px 20px;
    text-align: center;
    margin-top: 50px;
    border-radius: 8px;
  }
  
  .cta-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .cta-text {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  

  
  @media (max-width: 768px) {
    .services-list {
      flex-direction: column;
    }
  
    .service-card {
      width: 90%;
      margin: 10px auto;
    }
  
    .cta-container {
      padding: 30px 20px;
    }
  }
  