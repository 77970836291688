/* Basic styling for the Terms of Service page */
.terms-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0 auto;
    max-width: 1000px;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #004d99;
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 2rem;
    margin-top: 30px;
    color: #333;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.8;
    
  }
  
  ul {
    margin-left: 20px;
  }
  
  li {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }
  
  strong {
    font-weight: bold;
    color: #004d99;
  }
  
  
  @media (max-width: 768px) {
    .terms-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    p, li {
      font-size: 0.95rem;
    }
  }
  