/* Professional and Modern Styling for Report Section */

.report-container {
    max-width: 800px;
    margin: 5px auto;
    padding: 25px;
    background: linear-gradient(135deg, #ffffff, #f0f0f0);
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .report-container:hover {
    transform: translateY(-3px);
  }
  
  .report-title {
    font-size: 20px;
    font-weight: 700;
    color: #222;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .report-description {
    font-size: 18px;
    color: #555;
    margin-bottom: 25px;
    font-style: italic;
  }
  
  .report-list-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .report-list {
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 600px;
  }
  
  .report-item {
    margin: 15px 0;
    text-align: center;
  }
  
  .download-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background: linear-gradient(135deg, #007bff, #0056b3);
    border-radius: 55px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
  }
  
  .download-btn:hover {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 85, 170, 0.4);
  }
  
  