/* src/index.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background-color: #7575d1;
  color: #333;
  line-height: 1.6;
}

header {
  background-color: #333;
  padding: 10px 0;
}

header nav a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
}

header nav a:hover {
  color: #ff6347;
}

main {
  padding: 20px;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

h1, h2 {
  color: #333;
}

/* Form styling */
form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-top: 20px;
}

label {
  margin: 10px 0 5px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

button {
  padding: 10px;
  background-color: #ffffff; /* Soft, vibrant red background */
  color: #ffffff; /* White text for contrast */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #ffffff;
}
