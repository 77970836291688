.jobs-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .jobs-title {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 40px;
  }
  
  .job-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .job-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 45%;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .job-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  .job-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .job-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .job-location {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 20px;
  }
  
  .job-responsibilities,
  .job-qualifications {
    margin-top: 20px;
  }
  
  .job-responsibilities h3,
  .job-qualifications h3 {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .job-responsibilities ul,
  .job-qualifications ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .apply-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .apply-btn:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 768px) {
    .job-card {
      width: 100%;
    }
  }
  