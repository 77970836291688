/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
}

/* About Us Section */
.about-us {
  padding: 40px 20px;
  background-color: #fff;
}

.vision, .mission {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.vision h2, .mission h2 {
  color: #0056b3;
}

.vision p, .mission p {
  color: #555;
  font-size: 1.1rem;
}

/* Team Section */
.team {
  margin-bottom: 60px;
}

.team h2 {
  font-size: 2.2rem;
  margin-bottom: 30px;
  color: #0056b3;
}

.team-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1px;
  justify-items: center;
}

.team-member {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 2px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.team-member-photo {
  width: 120px;
  height: 140px;
  border-radius: 15%;
  object-fit: cover;
  margin-bottom: 20px;
}

.team-member-info h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #0e0d0d;
}

.team-member-info p {
  font-size: 1rem;
  color: #2c2929;
}
/* Responsive Design */
@media (max-width: 768px) {
  h2 {
    font-size: 1.8rem;
  }

  .vision p, .mission p {
    font-size: 1rem;
  }

  .team h2 {
    font-size: 2rem;
  }

  /* Change the team list to a single row */
  .team-list {
    display: flex;
    justify-content: space-between;
    gap: 2px;
    flex-wrap: wrap; /* Allows wrapping to the next line if needed */
  }

  .team-member {
    padding: 15px;
    flex: 1 1 calc(25% - 20px); /* 4 members per row, with space between */
    box-sizing: border-box;
  }

  .team-member-photo {
    width: 120px;
    height: 140px;
    border-radius: 15%;
  }
}

@media (max-width: 480px) {
  .vision p, .mission p {
    font-size: 0.9rem;
  }

  /* Team list remains in a single row but may wrap based on screen size */
  .team-list {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap; /* Wrap to next line if needed */
  }

  .team-member {
    flex: 1 1 calc(50% - 10px); /* 2 members per row */
  }

  .team-member-photo {
    width: 90px;
    height: 120px;
  }

  .team-member-info h3 {
    font-size: 1rem;
  }

  .team-member-info p {
    font-size: 0.9rem;
  }
}
