/* General Container */
.enroll-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f8fc;
    padding: 20px;
  }
  
  /* Card Styling */
  .enroll-card {
    background: #ffffff;
    border-radius: 12px;
    padding: 40px 30px;
    max-width: 500px;
    width: 90%;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.8s ease-in-out;
  }
  
  /* Title */
  .enroll-title {
    font-size: 2rem;
    color: #333333;
    margin-bottom: 20px;
    font-weight: 700;
  }
  
  /* Description */
  .enroll-description {
    font-size: 1.2rem;
    color: #555555;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  /* Enroll Button */
  .enroll-button {
    display: inline-block;
    background-color: #007bff;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    padding: 12px 30px;
    border-radius: 8px;
    text-decoration: none;
    box-shadow: 0px 6px 10px rgba(0, 123, 255, 0.2);
    transition: all 0.3s ease-in-out;
  }
  
  .enroll-button:hover {
    background-color: #0056b3;
    box-shadow: 0px 8px 15px rgba(0, 123, 255, 0.3);
    transform: translateY(-2px);
  }
  
  .enroll-button:active {
    background-color: #003f7f;
    box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.2);
    transform: translateY(2px);
  }
  
  /* Slide-Up Animation */
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  