/* Certificate Sample Section Styles */
.certificate-section {
    text-align: center;
    padding: 2em 1em;
    background-color: #f9f9f9;
  }
  
  .certificate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .certificate-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 8px;
    margin-bottom: 1em;
  }
  
  .certificate-info {
    text-align: center;
  }
  
  .download-btn {
    display: inline-block;
    padding: 0.5em 1em;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 1em;
  }
  
  .download-btn:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Layout */
  @media (min-width: 600px) {
    .certificate-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    .certificate-info {
      text-align: left;
      max-width: 200px;
      padding-left: 1em;
    }
  }
  