.enrollment-container {
    width: 80%;
    margin: auto;
    padding: 20px;
    text-align: center;
  }
  
  h2 {
    font-size: 24px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button.submit-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button.submit-btn:hover {
    background-color: #218838;
  }
  
  .thank-you-message {
    margin-top: 20px;
  }
  
  .thank-you-message h2 {
    color: #28a745;
  }
  