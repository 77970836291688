/* General Page Styles */
.login-signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f7fb;
  }
  
  .form-container {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
  }
  
  input:focus {
    border-color: #00ffb3; /* Highlight border on focus */
    outline: none;
  }
  
  button {
    padding: 12px;
    background-color: #ffffff;
    border: none;
    color: white;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  z
  button:hover {
    background-color: #00e6a1;
  }
  
  .switch-link {
    text-align: center;
    margin-top: 20px;
  }
  
  .switch-link a {
    color: #00ffb3;
    text-decoration: none;
  }
  
  .switch-link a:hover {
    text-decoration: underline;
  }
  
  /* Error Message */
  .error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
  }
  